<template>
  <div>
    <validation-provider
      #default="{ errors }"
      :name="questionTitle"
      rules="required"
    >
      <b-form-group
        :label="questionTitle"
        :label-for="'item_' + itemKey"
      >
        <v-select
          v-if="questionType === 'select'"
          :id="'item_' + itemKey"
          v-model="investorProfileResult.questions[itemKey].id_investor_options"
          :options="optionsData"
          label="title"
          :reduce="option => option.id"
          :placeholder="$t('Seçiniz')"
        />
        <v-select
          v-if="questionType === 'checkbox'"
          :id="'item_' + itemKey"
          v-model="investorProfileResult.questions[itemKey].multiple_options"
          :options="optionsData"
          label="title"
          :reduce="option => option.id"
          :placeholder="$t('Seçiniz')"
          multiple
        />
        <b-form-input
          v-if="questionType === 'text'"
          :id="'item_' + itemKey"
          v-model="investorProfileResult.questions[itemKey].text"
          :placeholder="questionTitle"
        />
      </b-form-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'Questions',
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
    vSelect,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
    questionTitle: {
      type: String,
      required: true,
    },
    questionType: {
      type: String,
      required: true,
    },
    optionsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    investorProfileResult() {
      return this.$store.getters['investorProfile/getInvestorProfileResult']
    },
    investorCreateForm() {
      return this.$store.getters['investorProfile/getInvestorProfileForm']
    },
  },
  created() {
    localize(this.$i18n.locale)
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
