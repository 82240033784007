<template>
  <div>
    <validation-provider
      #default="{ errors }"
      :name="$t('Firma Adı')"
      rules="required"
    >
      <b-form-group
        :label="$t('Firma Adı')"
        label-for="company"
      >
        <b-form-input
          id="company"
          v-model="investorProfileResult['investors'].company"
          :placeholder="$t('Firma Adı')"
        />
      </b-form-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
    <validation-provider
      #default="{ errors }"
      :name="$t('Açıklama')"
      rules="required"
    >
      <b-form-group
        :label="$t('Açıklama')"
        label-for="content"
      >
        <b-form-textarea
          id="content"
          v-model="investorProfileResult['investors'].content"
          :placeholder="$t('Açıklama')"
        />
      </b-form-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BFormTextarea } from 'bootstrap-vue'
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Company',
  components: {
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BFormTextarea,
  },
  computed: {
    investorProfileResult() {
      return this.$store.getters['investorProfile/getInvestorProfileResult']
    },
  },
  created() {
    localize(this.$i18n.locale)
  },
}
</script>

<style scoped>

</style>
